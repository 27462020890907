/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import cn from "classnames";

import { tagular } from "@cohesion/tagular";
import { useElemRef } from "@utils/useElemRef";
import { useMobile } from "@utils/component";

import { Controls } from "./controls";

export function ImageCarousel({
  activeItem = 0,
  className = "",
  scrollPadding = true,
  listClassNames = "",
  tagularPosition = "CAROUSEL",
  prevArrowTagularPosition = "",
  nextArrowTagularPosition = "",
  tagularName = "",
  polite = false,
  tagularLocation = "SECTION",
  children,
  itemMeta,
}) {
  const [carouselRef, setRef] = useElemRef(node => node);
  const isMobile = useMobile();
  const { offsetWidth, scrollLeft, scrollWidth } = carouselRef || {};
  const [carouselItemWidth, setCarouselItemWidth] = useState(0);
  const [activeChild, setActiveChild] = useState(0);

  const [scrollPosition, setScrollPosition] = useState({
    pageWidth: 0,
    head: 0,
    tail: null,
  });

  const getScrollPosition = elem => {
    // hax until refactor :point-down:
    // eslint-disable-next-line no-shadow
    const { offsetWidth, scrollLeft, scrollWidth } = elem || {};

    return {
      pageWidth: offsetWidth,
      head: scrollLeft,
      tail: scrollWidth - offsetWidth - scrollLeft,
    };
  };

  const scroll = left => {
    carouselRef.scroll({
      top: 0,
      left,
      behavior: "smooth",
    });
  };

  const next = useCallback(() => {
    const { head, tail, pageWidth } = getScrollPosition(carouselRef);
    scroll(tail >= pageWidth ? head + pageWidth : head + tail);

    tagular("click", {
      actionOutcome: "SLIDE",
      outboundUrl: "NULL",
      webElement: {
        location: tagularLocation,
        elementType: "BUTTON",
        position: nextArrowTagularPosition || tagularPosition,
        text: "NEXT ARROW",
        ...(tagularName && { name: tagularName }),
      },
    });
  }, [carouselRef, tagularPosition, tagularLocation, tagularName]);

  const prev = useCallback(() => {
    const { head, pageWidth } = getScrollPosition(carouselRef);

    scroll(head >= pageWidth ? head - pageWidth : 0);

    tagular("click", {
      actionOutcome: "SLIDE",
      outboundUrl: "NULL",
      webElement: {
        location: tagularLocation,
        elementType: "BUTTON",
        position: prevArrowTagularPosition || tagularPosition,
        text: "PREV ARROW",
        ...(tagularName && { name: tagularName }),
      },
    });
  }, [carouselRef, tagularPosition, tagularLocation, tagularName]);

  const handleScroll = debounce(
    () => {
      const position = getScrollPosition(carouselRef);

      setScrollPosition(position);

      // Calculate the active child index based on scroll position and carousel item width
      if (carouselItemWidth > 0) {
        const activeChildIndex = Math.round(position.head / carouselItemWidth);
        setActiveChild(activeChildIndex);
      }
    },
    20,
    { leading: true, trailing: true }
  );

  const handleScrollEnd = useMemo(
    () =>
      debounce(() => {
        const prevPosition = scrollPosition.head;
        const position = getScrollPosition(carouselRef);
        const progressing = position.head > prevPosition;
        if (progressing && isMobile) {
          tagular("swipe", {
            actionOutcome: "SLIDE",
            webElement: {
              location: tagularLocation || "CARDS",
              elementType: "ARROW",
              position: tagularPosition,
              name: tagularName,
              text: "",
            },
          });
        }
      }, 600),
    [isMobile, carouselRef]
  );

  // Updates the scroll position when properties we care about update on carouselRef
  useEffect(() => {
    setScrollPosition(getScrollPosition(carouselRef || {}));
  }, [offsetWidth, scrollLeft, scrollWidth, carouselRef]);

  /**
   * In instances where the carousel is tied to another component (eg mobile map), we can
   * provide the active item and have the carousel scroll to that as it's changed.
   */
  useEffect(() => {
    if (activeItem) {
      const { offsetLeft: item } = carouselRef.children[activeItem];
      scroll(item);
    }
  }, [activeItem]);

  // Retrieve carousel item width for active child index calculation
  useEffect(() => {
    if (carouselRef && carouselRef.children.length > 0) {
      // Assume equal width for all carousel items
      setCarouselItemWidth(carouselRef.children[0].offsetWidth);
    }
  }, [carouselRef, carouselRef?.children]);

  return (
    <div
      className={cn(
        "carousel-container max-w-full overflow-x-hidden space-y-4",
        className
      )}
    >
      <ul
        className={cn(
          "carousel flex overflow-x-scroll md:overflow-x-auto overflow-y-hidden -mx-4",
          listClassNames
        )}
        ref={setRef}
        onScroll={() => {
          handleScroll();
          handleScrollEnd();
        }}
        {...(polite ? { "aria-live": "polite" } : {})}
      >
        {children.map(child => {
          const { key } = child;

          return (
            <li key={key} className="carousel-item flex-shrink-0 px-4 w-full">
              {child}
            </li>
          );
        })}
      </ul>
      <div className="flex flex-wrap sm:flex-nowrap mx-auto container xl:max-w-6xl space-y-4 sm:space-y-0">
        <p className="md:mx-12 grow text-xs w-full sm:w-auto text-balance">
          {itemMeta[activeChild]?.figcaption}
        </p>
        <Controls
          className="controls w-content ml-auto"
          onPrev={prev}
          onNext={next}
          scrollPosition={scrollPosition}
          tagularPosition={tagularPosition}
          tagularName={tagularName}
        />
      </div>

      <style jsx>{`
        .carousel {
          scroll-snap-type: x mandatory;
          scroll-behavior: smooth;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          scrollbar-width: none;
          scroll-padding-right: ${scrollPadding ? "32px" : 0};
        }
        .carousel::-webkit-scrollbar {
          display: none;
        }
        .carousel-item {
          scroll-snap-align: start;
        }
      `}</style>
    </div>
  );
}

export const carouselPropTypes = {
  itemMeta: PropTypes.arrayOf(
    PropTypes.shape({
      figcaption: PropTypes.string,
    })
  ),
  activeItem: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  listClassNames: PropTypes.string,
  scrollPadding: PropTypes.bool,
  tagularPosition: PropTypes.string,
  prevArrowTagularPosition: PropTypes.string,
  nextArrowTagularPosition: PropTypes.string,
  tagularName: PropTypes.string,
  polite: PropTypes.bool,
  showMobileControls: PropTypes.bool,
  showMobileIndicators: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  tagularLocation: PropTypes.string,
};

ImageCarousel.propTypes = carouselPropTypes;
